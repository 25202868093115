import { GENERATE_URL } from "@services/helpers/request";

const PREFIX_URL = "File";

export const Get = (options) => GENERATE_URL(`${PREFIX_URL}`, options);

export const Create = (options) =>
  GENERATE_URL(`${PREFIX_URL}/AddFile`, options);

export default {
  Get,
  Create,
};
