<template>
  <div
    @click="$emit('click', $event)"
    class="c-chip"
    :class="`c-chip__variant--${variant} ${
      size.length > 0 ? `c-chip__variant--${size}` : ''
    }`"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      default: "primary",
    },
    size: {
      default: "",
    },
  },
};
</script>

<style></style>
