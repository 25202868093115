<template>
  <div>
    <Chat></Chat>
  </div>
</template>

<script>
import Chat from "@/components/Chat/index.vue";
export default {
  components: {
    Chat,
  },
};
</script>

<style></style>
