import Requests from "@/services/Requests";

export default {
  data() {
    return {
      customerPackages: [],
      activeCustomerPackage: null,
      removeListenCustomerPackage: false,
    };
  },
  methods: {
    async getCustomerPackages() {
      this.isLoading = true;
      console.log(this.selectedServices, "sssss");

      const response =
        await Requests.CustomerPackage.GetCustomerPackageByCompanyId({
          query: {
            "filter[companyId]": this.company.id,
            "filter[customerId]": this.customer.id,
            "filter[companyServiceIds]": this.selectedServices,
          },
        });

      if (response.message === "OK") {
        const customerPackages = response.data.items;
        if (!customerPackages.length)
          return this.showDateTimeDrawerWithMessage(); // randevu saati seçmeye devam et.
        else this.createMultipleCustomerPackageSelectMessage(customerPackages);

        this.customerPackages = customerPackages;
      }
    },
    createMultipleCustomerPackageSelectMessage(customerPackages) {
      const createHtml = customerPackages
        .map(
          (customerPackage) =>
            `<button class="btn-block u-margin-top-small btn btn-light-primary" id="${
              customerPackage.id
            }">${customerPackage?.packageInfo?.name} (${this.$t(
              "common.remainCount",
              { count: customerPackage?.packageInfo.sessionCount }
            )}) </button>`
        )
        .join("");
      this.sendBotMessage(
        this.$t("chat.message.bot.multipleCustomerPackage", {
          html: createHtml,
          id: "noPackageButton",
          name: this.company.name,
          count: customerPackages.length,
        }),
        "",
        () => {}
      );
      this.showTextArea = false;
      const vm = this;
      document.body.addEventListener("click", (e) => {
        if (this.activeCustomerPackage || this.removeListenCustomerPackage)
          return;

        if (
          vm.customerPackages.find(
            (customerPackage) => customerPackage.id === e.target.id
          )
        ) {
          vm.activeCustomerPackage = customerPackages.find(
            (customerPackage) => customerPackage.id === e.target.id
          );
          vm.selectedCustomerPackageMessage(vm.activeCustomerPackage);
          vm.isLoading = false;
        } else if (e.target.id === "noPackageButton") {
          vm.selectedNoCustomerPackageMessage(null);
          vm.isLoading = false;
        }
      });
    },
    selectedCustomerPackageMessage(activeCustomerPackage) {
      this.sendBotMessage(
        this.$t("chat.message.bot.selectedCustomerPackage", {
          title: activeCustomerPackage.packageInfo.name,
        }),
        this.$t("chat.message.bot.searchServicePlaceholder")
      );
      this.sendSelectedCustomerPackage();
    },
    selectedNoCustomerPackageMessage() {
      this.removeListenCustomerPackage = true;
      this.sendBotMessage(
        this.$t("chat.message.bot.selectedNoCustomerPackage"),
        ""
      );
      this.sendSelectedCustomerPackage();
    },
    sendSelectedCustomerPackage() {
      setTimeout(() => {
        this.showDateTimeDrawerWithMessage();
      }, 1000);
    },
  },
};
