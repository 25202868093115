// import Requests from "@/services/Requests";

export default {
  data() {
    return {
      showDateTimeDrawer: false,
      date: null,
      time: null,
    };
  },
  methods: {
    showDateTimeDrawerWithMessage() {
      this.showDateTimeDrawer = true;
      this.sendBotMessage(
        this.$t("chat.message.bot.selectDateTime", { id: "showDrawer" }),
        "primary"
      );

      const vm = this;
      setTimeout(() => {
        document.getElementById("showDrawer").addEventListener("click", (e) => {
          e.preventDefault();
          vm.showDateTimeDrawer = true;
        });
      }, 500);
    },
  },
};
