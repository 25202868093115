import Api from "gurhan/helpers/Api";
import router from "@router";
import { BASE_FILE_URL } from "./BaseUrl";
/**
 * Tüm requestler için yazılmış API HELPER module'üdür.
 * api.get(url);
 *
 * api.post(url, data);
 *
 * api.put(url, data)
 *
 * api.delete(url, data)
 *
 * api.token = String - Tüm requestlerde headers kısmına ekler.
 *
 * @param {object} Options - API bağlantı bilgileri
 *
 * @param {string} Options.baseUrl - Base url
 */
const api = new Api({
  baseUrl: BASE_FILE_URL, // api adresi,
  bearerToken: true, // bearer token kullanılacaksa true değilse false,
  errorStatus: [400, 401, 403, 404, 500, 502], // uygulama hangi hata code'larını aldığında login'e gönderilir.
  loginRouterName: "",
  loginUrl: "/#/account/sign-in", // login için hangi url'e istek atacağını belirtir.
  vueRouter: router, // uygulamanın route bilgisini gönderir ve içerideki yönlendirmeleri yapar.
});

export default api;
