var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-avatar",class:{
    'c-avatar__variant--text': _vm.showText,
    'c-avatar__variant--clickable': _vm.clickable,
    'c-avatar__variant--card': _vm.type === 'card',
    'c-avatar__variant--huge': _vm.size === 'huge',
    'c-avatar__variant--small': _vm.size === 'sm',
    'c-avatar__variant--large': _vm.size === 'lg',
    'c-avatar__variant--xlarge': _vm.size === 'xl',
    'c-avatar__variant--online': _vm.status === 'online',
    'c-avatar__variant--offline': _vm.status === 'offline',
  },on:{"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.fileSyncLoading)?_c('span',{staticClass:"u-display-inline-flex u-align-items-center"},[(_vm.fileSyncLoading)?_c('loading'):_vm._e()],1):(_vm.hasSlot)?_vm._t("default"):(!_vm.showText)?_c('img',{staticClass:"c-avatar__img",attrs:{"src":_vm.syncSrc || _vm.src,"alt":"user-profile"},on:{"error":(e) => (_vm.showText = true)}}):(_vm.text !== '')?_c('span',{staticClass:"c-avatar__text"},[_vm._v(_vm._s(_vm.textComputed))]):_c('i',{class:`icofont-${_vm.icon}`})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }