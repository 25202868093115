<template>
  <div class="s-chat__content">
    <h1 class="s-chat__content__header">
      <div class="row u-align-items-center">
        <div class="es-xs-2">
          <avatar
            file-sync
            :src="company.image"
            size="xs"
            status="online"
            icon="robot"
          ></avatar>
        </div>
        <div class="es-xs-9 u-margin-left-xsmall">
          <div class="u-line-1">{{ $t("chat.content.title") }}</div>
          <div class="u-line-1">
            <small class="u-font-size-xsmall">{{ company.name }}</small>
          </div>
        </div>
        <div>
          <small> </small>
        </div>
      </div>
      <i @click="CLOSE_CHAT()" class="u-cursor-pointer icofont-close"></i>
    </h1>
    <div
      id="body"
      class="s-chat__content__body"
      :class="{
        's-chat__content__body--ended': !(!chatIsEnded && showTextArea),
      }"
    >
      <div class="s-chat__content__body__greeting" v-if="messages.length === 0">
        <h1><i class="icofont-robot"></i></h1>
        <p>{{ $t("chat.content.greeting") }}</p>
      </div>
      <div
        class="s-chat__content__body__message"
        :class="{
          's-chat__content__body__message--me': message.sender === 'me',
        }"
        v-for="(message, i) in messages"
        :key="i"
      >
        <span
          class="s-chat__content__body__message__text"
          :class="{
            's-chat__content__body__message__text--bot':
              message.sender === 'bot',
          }"
          v-html="message.text"
        >
        </span>
        <span
          class="s-chat__content__body__message__time"
          :class="{
            's-chat__content__body__message__time--me': message.sender === 'me',
          }"
          >{{ message.time }}
        </span>
      </div>
    </div>
    <div v-if="!chatIsEnded && showTextArea" class="s-chat__content__footer">
      <div class="u-text-align-center" v-if="isLoading">
        <small class="u-color-primary">
          <i>
            <i class="icofont-robot"></i> {{ $t("chat.content.title") }}
            {{ $t("chat.content.writing") }}</i
          >
        </small>
      </div>
      <form @submit="sendButton">
        <div class="row u-align-items-center">
          <div class="es-xs-9 es-md-10 es-lg-11">
            <BaseInput
              :readonly="isLoading"
              inputId="messageInput"
              :placeholder="
                activeBotMessage?.placeholder || $t('chat.content.placeholder')
              "
              block
              v-model="message"
            ></BaseInput>
          </div>
          <div class="es-xs-3 es-md-2 es-lg-1">
            <Button
              @submit="sendButton"
              @click="sendButton"
              variant="primary"
              :disabled="isLoading"
              block
              icon="paper-plane"
            >
            </Button>
          </div>
        </div>
      </form>
    </div>
    <drawer
      align="bottom"
      v-model="showDateTimeDrawer"
      :title="$t('chat.content.reservationTitle')"
    >
      <ReservationIsCreated
        :reservation="reservation"
        :customer="customer"
        :company="company"
        :activeRoom="activeRoom"
        :selectedServices="selectedServices"
        :services="services"
        v-if="showCreatedReservation"
      ></ReservationIsCreated>
      <CreateDateTime
        v-else
        @create:reservation="sendReservation"
        :totalPrice="totalServicePrice()"
        @cancel:reservation="showDateTimeDrawer = false"
        :activeRoom="activeRoom"
        :company="company"
      ></CreateDateTime>
    </drawer>
  </div>
</template>

<script>
import BaseInput from "../Base/Inputs/BaseInput.vue";
import Button from "../Base/Button.vue";
import Avatar from "../Base/Avatar.vue";
import RoomMixin from "./mixins/Room";
import ServiceMixin from "./mixins/Service";
import CustomerPackagesMixin from "./mixins/CustomerPackages";
import SelectTimeMixin from "./mixins/SelectTime";
import Requests from "@/services/Requests";
import CreateDateTime from "./CreateDateTime.vue";
import ReservationIsCreated from "./ReservationIsCreated.vue";
import $api from "@/services/Api";
import Drawer from "../Base/Drawer.vue";
function convertTimeToDate(date, time) {
  const newDate = date.replace("T00:00:00", `T${time}:00`);
  return newDate;
}
// eslint-disable-next-line
export default {
  mixins: [RoomMixin, ServiceMixin, CustomerPackagesMixin, SelectTimeMixin],
  props: {
    company: {
      type: Object,
      required: true,
    },
    iframeWebsite: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseInput,
    Avatar,
    Button,
    CreateDateTime,
    ReservationIsCreated,
    Drawer,
  },
  data() {
    return {
      reservation: null,
      showCreatedReservation: false,
      showTextArea: true,
      chatIsEnded: false,
      messages: [],
      message: "",
      isLoading: true,
      customer: null,
      form: {
        phone: "",
      },
    };
  },
  computed: {
    activeBotMessage() {
      const messages = this.messages.filter(
        (message) => message.sender === "bot"
      );
      if (messages.length === 0) return null;
      return messages[messages.length - 1];
    },
  },
  methods: {
    CLOSE_CHAT() {
      this.$emit("start:chat", "Greetings");
      if (this.iframeWebsite) {
        window.parent.postMessage("closeChat", "*");
      }
    },
    async sendReservation(dateInfo, loading) {
      loading(true);
      const data = {
        customerId: this.customer.id,
        roomId: this.activeRoom.id,
        companyId: this.company.id,
        companyServiceIds: this.selectedServices,
        reservationDate: dateInfo.date.isoStringDate,
        reservationTime: convertTimeToDate(
          dateInfo.date.isoStringDate,
          dateInfo.time.calendarTime
        ),
        customerPackageId: this.activeCustomerPackage?.id || null,
      };
      const response = await Requests.Reservation.Create(data);
      if (response.message === "OK") {
        this.reservation = { ...data, id: response.data };

        this.showCreatedReservation = true;
        this.endChat();
      } else {
        this.$store.dispatch("SET_ACTION_MODAL", {
          isLoading: false,
          status: true,
          message: this.$t("authMessages.somethingWentWrong"),
          variant: "danger",
          duration: 10000,
        });
      }
      //TODO
      loading(false);
    },
    sendButton(e) {
      if (e.preventDefault) e.preventDefault();
      if (!this.message) return;
      const message = this.message;
      this.sendMessage();
      this.activeBotMessage.func(message);
    },
    scrollToBottom() {
      setTimeout(() => {
        const element = document.getElementById("body");
        if (element) element.scrollTop = element.scrollHeight;
      }, 250);
    },
    sendBotMessage(message = "", placeholder = "", func = () => {}) {
      this.messages.push({
        sender: "bot",
        text: message,
        placeholder,
        time: new Date().toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }),
        func,
      });
      this.scrollToBottom();
    },
    sendMessage() {
      this.messages.push({
        sender: "me",
        text: this.message,
        time: new Date().toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }),
        func: () => {},
      });
      this.message = "";
      this.scrollToBottom();
    },
    async sendCodeToPhone(messageParam) {
      // rakamlar ve "+" yı kabul et
      const message = messageParam.replace(/[^0-9+]/g, "");
      this.isLoading = true;
      const valid = this.validationPhoneNumber(message);
      if (!valid) {
        this.isLoading = false;
        return this.sendBotMessage(
          this.$t("chat.message.bot.invalidPhoneNumber"),
          this.$t("chat.message.bot.givePhoneNumberPlaceholder"),
          this.sendCodeToPhone
        );
      }
      const response = await Requests.Validation.SendValidationCode({
        phoneNo: message,
        companyId: this.company.id,
      });
      if (response.message === "OK") {
        this.isLoading = false;
        this.form.phone = message;
        this.sendBotMessage(
          this.$t("chat.message.bot.codeSent"),
          this.$t("chat.message.bot.codeSentPlaceholder"),
          this.enterCode
        );
      } else {
        this.isLoading = false;
        this.sendBotMessage(
          this.$t("chat.message.bot.codeCouldntSent"),
          "",
          () => {}
        );
        this.endChat();
      }
    },
    validationPhoneNumber(message) {
      console.log("message vaid", message);

      return message.startsWith("+") && !isNaN(message.slice(1));
    },
    async enterCode(message) {
      this.isLoading = true;
      const rnd = Math.floor(Math.random() * 1000);
      // kod dogrulama
      const response = await Requests.Validation.ValidationCode({
        code: message,
        phoneNo: this.form.phone,
        companyId: this.company.id,
      });
      if (response.message === "OK") {
        $api.token = response.data.token;
        if (response.data.customerInfo && response.data.isValid) {
          this.customer = response.data.customerInfo;

          this.sendBotMessage(
            this.$t("chat.message.bot.validCode", {
              fullname: this.customer.fullname,
            }),
            this.$t("chat.message.bot.codeSentPlaceholder"),
            // sabah devam et!
            () => {}
          );
          setTimeout(() => {
            this.getRooms();
          }, 500);
        } else if (!response.data.customerInfo && response.data.isValid) {
          this.sendBotMessage(
            this.$t("chat.message.bot.enterFullname"),
            this.$t("chat.message.bot.enterFullnamePlaceholder"),
            // sabah devam et!
            this.enterFullname
          );
        } else {
          this.sendBotMessage(
            this.$t("chat.message.bot.invalidCode", {
              attr: `id="reSendCode${rnd}"`,
            }),
            this.$t("chat.message.bot.codeSentPlaceholder"),
            this.enterCode
          );
          this.isLoading = false;
          const vm = this;
          setTimeout(() => {
            document.getElementById(`reSendCode${rnd}`).addEventListener(
              "click",
              (e) => {
                e.preventDefault();
                vm.sendCodeToPhone(this.form.phone);
              },
              { once: true }
            );
          }, 250);
        }
      } else if (response.message === "MODEL_ERROR") {
        this.sendBotMessage(
          this.$t("chat.message.bot.invalidCode", {
            attr: `id="reSendCode${rnd}"`,
          }),
          this.$t("chat.message.bot.codeSentPlaceholder"),
          this.enterCode
        );
        const vm = this;
        setTimeout(() => {
          document.getElementById(`reSendCode${rnd}`).addEventListener(
            "click",
            (e) => {
              e.preventDefault();
              vm.sendCodeToPhone(this.form.phone);
            },
            { once: true }
          );
        }, 250);
      } else {
        this.sendBotMessage(
          this.$t("chat.message.bot.codeCouldntSent"),
          "",
          () => {}
        );
        this.endChat();
      }
      this.isLoading = false;
    },
    enterFullname(message) {
      this.isLoading = true;
      this.form.fullname = message;
      const rnd = Math.floor(Math.random() * 1000);
      const rnd2 = Math.floor(Math.random() * 1000);

      this.sendBotMessage(
        this.$t("chat.message.bot.enteredFullname", {
          fullname: this.form.fullname,
          attr: `id="savedInfo${rnd}"`,
          attr2: `id="declineSaveInfo${rnd2}"`,
        }),
        this.$t("chat.message.bot.enterFullnamePlaceholder"),
        // sabah devam et!
        () => {}
      );
      const vm = this;
      setTimeout(() => {
        document.getElementById(`savedInfo${rnd}`).addEventListener(
          "click",
          (e) => {
            e.preventDefault();
            vm.savedInfo(this.form.phone);
          },
          { once: true }
        );
        document.getElementById(`declineSaveInfo${rnd2}`).addEventListener(
          "click",
          (e) => {
            e.preventDefault();
            vm.declineSaveInfo(this.form.phone);
          },
          {
            once: true,
          }
        );
        vm.isLoading = false;
      }, 250);
    },
    async declineSaveInfo() {
      this.isLoading = true;
      const response = await Requests.Customer.CreateContact({
        companyId: this.company.id,
        fullname: this.form.fullname,
        phoneNo: this.form.phone,
        isTemporary: true,
      });
      if (response.message === "OK") {
        this.customer = response.data;
        this.sendBotMessage(
          this.$t("chat.message.bot.declineSaveInfo", {
            fullname: this.form.fullname,
          }),
          "",
          //TODO odaları çek
          () => {}
        );
        this.getRooms();
      } else {
        this.sendBotMessage(
          this.$t("chat.message.bot.couldntDeclineSaveInfo", {
            fullname: this.form.fullname,
          }),
          "",
          () => {}
        );
        this.endChat();
      }
      this.isLoading = false;
    },
    async savedInfo() {
      this.isLoading = true;

      //TODO odaları çek
      const response = await Requests.Customer.CreateContact({
        companyId: this.company.id,
        fullname: this.form.fullname,
        phoneNo: this.form.phone,
        isTemporary: false,
      });
      if (response.message === "OK") {
        this.customer = response.data;
        this.sendBotMessage(
          this.$t("chat.message.bot.savedInfo", {
            fullname: this.form.fullname,
          }),
          "",
          () => {}
        );
        this.getRooms();
      } else {
        this.sendBotMessage(
          this.$t("chat.message.bot.couldntSavedInfo", {
            fullname: this.form.fullname,
          }),
          "",
          () => {}
        );
        this.endChat();
        this.isLoading = false;
      }
    },
    startChat() {
      this.isLoading = true;
      setTimeout(() => {
        this.sendBotMessage(this.$t("chat.message.bot.welcome"));
        setTimeout(() => {
          this.sendBotMessage(
            this.$t("chat.message.bot.givePhoneNumber"),
            this.$t("chat.message.bot.givePhoneNumberPlaceholder"),
            this.sendCodeToPhone
          );
          document.getElementById("messageInput")?.focus();

          this.isLoading = false;
        }, 1000);
      }, 500);
    },
    endChat() {
      this.sendBotMessage(this.$t("chat.message.bot.endChat"), "", () => {});
      this.chatIsEnded = true;
      this.isLoading = false;
    },
  },
  mounted() {
    this.startChat();
  },
};
</script>

<style></style>
