var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-chat__content"},[_c('h1',{staticClass:"s-chat__content__header"},[_c('div',{staticClass:"row u-align-items-center"},[_c('div',{staticClass:"es-xs-2"},[_c('avatar',{attrs:{"file-sync":"","src":_vm.company.image,"size":"xs","status":"online","icon":"robot"}})],1),_c('div',{staticClass:"es-xs-9 u-margin-left-xsmall"},[_c('div',{staticClass:"u-line-1"},[_vm._v(_vm._s(_vm.$t("chat.content.title")))]),_c('div',{staticClass:"u-line-1"},[_c('small',{staticClass:"u-font-size-xsmall"},[_vm._v(_vm._s(_vm.company.name))])])]),_vm._m(0)]),_c('i',{staticClass:"u-cursor-pointer icofont-close",on:{"click":function($event){return _vm.CLOSE_CHAT()}}})]),_c('div',{staticClass:"s-chat__content__body",class:{
      's-chat__content__body--ended': !(!_vm.chatIsEnded && _vm.showTextArea),
    },attrs:{"id":"body"}},[(_vm.messages.length === 0)?_c('div',{staticClass:"s-chat__content__body__greeting"},[_vm._m(1),_c('p',[_vm._v(_vm._s(_vm.$t("chat.content.greeting")))])]):_vm._e(),_vm._l((_vm.messages),function(message,i){return _c('div',{key:i,staticClass:"s-chat__content__body__message",class:{
        's-chat__content__body__message--me': message.sender === 'me',
      }},[_c('span',{staticClass:"s-chat__content__body__message__text",class:{
          's-chat__content__body__message__text--bot':
            message.sender === 'bot',
        },domProps:{"innerHTML":_vm._s(message.text)}}),_c('span',{staticClass:"s-chat__content__body__message__time",class:{
          's-chat__content__body__message__time--me': message.sender === 'me',
        }},[_vm._v(_vm._s(message.time)+" ")])])})],2),(!_vm.chatIsEnded && _vm.showTextArea)?_c('div',{staticClass:"s-chat__content__footer"},[(_vm.isLoading)?_c('div',{staticClass:"u-text-align-center"},[_c('small',{staticClass:"u-color-primary"},[_c('i',[_c('i',{staticClass:"icofont-robot"}),_vm._v(" "+_vm._s(_vm.$t("chat.content.title"))+" "+_vm._s(_vm.$t("chat.content.writing")))])])]):_vm._e(),_c('form',{on:{"submit":_vm.sendButton}},[_c('div',{staticClass:"row u-align-items-center"},[_c('div',{staticClass:"es-xs-9 es-md-10 es-lg-11"},[_c('BaseInput',{attrs:{"readonly":_vm.isLoading,"inputId":"messageInput","placeholder":_vm.activeBotMessage?.placeholder || _vm.$t('chat.content.placeholder'),"block":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('div',{staticClass:"es-xs-3 es-md-2 es-lg-1"},[_c('Button',{attrs:{"variant":"primary","disabled":_vm.isLoading,"block":"","icon":"paper-plane"},on:{"submit":_vm.sendButton,"click":_vm.sendButton}})],1)])])]):_vm._e(),_c('drawer',{attrs:{"align":"bottom","title":_vm.$t('chat.content.reservationTitle')},model:{value:(_vm.showDateTimeDrawer),callback:function ($$v) {_vm.showDateTimeDrawer=$$v},expression:"showDateTimeDrawer"}},[(_vm.showCreatedReservation)?_c('ReservationIsCreated',{attrs:{"reservation":_vm.reservation,"customer":_vm.customer,"company":_vm.company,"activeRoom":_vm.activeRoom,"selectedServices":_vm.selectedServices,"services":_vm.services}}):_c('CreateDateTime',{attrs:{"totalPrice":_vm.totalServicePrice(),"activeRoom":_vm.activeRoom,"company":_vm.company},on:{"create:reservation":_vm.sendReservation,"cancel:reservation":function($event){_vm.showDateTimeDrawer = false}}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('small')])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_c('i',{staticClass:"icofont-robot"})])
}]

export { render, staticRenderFns }