<template>
  <div v-if="value" class="c-drawer">
    <transition
      :leave-active-class="`c-drawer__content__align--${align}-leave`"
    >
      <div
        v-click-outside="CLICK_OUTSIDE"
        class="c-drawer__content"
        :class="`c-drawer__content__align--${align} ${
          inside ? 'c-drawer__inside' : ''
        } ${`c-drawer__content__variant--${size}`}`"
      >
        <header class="c-drawer__content__header">
          <h4>{{ title }}</h4>
          <i
            @click="$emit('input', false)"
            class="c-drawer__content__header__icon icofont-close"
          ></i>
        </header>
        <div class="c-drawer__content__content">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
    },
    inside: {
      default: false,
      type: Boolean,
    },
    persistent: {
      default: false,
      type: Boolean,
    },
    align: {
      default: "right",
    },
    size: {
      default: "",
    },
    title: {
      default: "",
    },
  },
  data() {
    return {
      init: false,
    };
  },
  watch: {
    value(data) {
      if (data) {
        setTimeout(() => {
          this.init = true;
        }, 100);
      } else {
        this.init = false;
      }
    },
  },
  methods: {
    CLICK_OUTSIDE() {
      if (!this.init || this.persistent) return;
      this.$emit("input", false);
    },
  },
};
</script>

<style></style>
