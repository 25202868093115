<template>
  <div class="s-chat__greetings">
    <div class="s-chat__greetings__header">
      <h1>{{ $t("chat.greetings.hello") }}</h1>
      <p>{{ $t("chat.greetings.description") }}</p>
    </div>
    <div class="s-chat__greetings__body">
      <p v-html="$t('chat.greetings.description1', { name: company.name })"></p>
      <hr />
      <p v-if="!iframeWebsite">{{ $t("chat.greetings.description2") }}</p>
      <p v-else>
        <i>{{ $t("chat.greetings.letsStart") }}</i>
      </p>
      <Button
        @click="$emit('start:chat', 'Content')"
        class="u-margin-top-xlarge"
        block
        variant="primary"
      >
        <i class="icofont-plus"></i>
        {{ $t("chat.greetings.getStarted") }}
      </Button>
    </div>
    <div class="s-chat__greetings__footer">
      <img src="@/assets/images/Logo.png" width="70" alt="" />
      <avatar file-sync :src="company.image"></avatar>
    </div>
  </div>
</template>

<script>
import Button from "../Base/Button.vue";
import Avatar from "../Base/Avatar.vue";
export default {
  props: {
    company: {
      default: () => ({}),
      required: true,
    },
    iframeWebsite: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    Button,
    Avatar,
  },
};
</script>

<style></style>
