<template>
  <div class="s-chat__content__body__greeting u-padding">
    <h1><i class="icofont-robot"></i></h1>
    <p>{{ $t("chat.content.notfound") }}</p>
    <div class="u-margin-top">
      <img src="@/assets/images/Logo.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
