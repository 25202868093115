<template>
  <div class="s-chat">
    <loading v-if="isCompanyLoading"></loading>
    <CompanyNotFound v-else-if="!company"></CompanyNotFound>
    <component
      v-else
      :iframeWebsite="iframeWebsite"
      :company="company"
      @start:chat="activeComponent = $event"
      :is="activeComponent"
    ></component>
  </div>
</template>

<script>
import Greetings from "./Greetings.vue";
import Content from "./Content.vue";
import CompanyNotFound from "./CompanyNotFound.vue";
import Requests from "@/services/Requests";
export default {
  name: "Chat",
  components: {
    Greetings,
    CompanyNotFound,
    Content,
  },
  data() {
    return {
      iframeWebsite: false,
      isCompanyLoading: true,
      company: null,
      activeComponent: "Greetings",
    };
  },
  methods: {
    iniFrame() {
      if (window.location !== window.parent.location) {
        // The page is in an iFrames
        this.iframeWebsite = true;
      } else {
        // The page is not in an iFrame
        this.iframeWebsite = false;
      }
    },
    async GET_COMPANY() {
      this.isCompanyLoading = true;
      const response = await Requests.Company.GetCompanyIdBySlug({
        query: { "filter[slug]": this.$route.params.slug },
      });
      if (response.message === "OK") {
        this.company = response.data;
      }
      this.isCompanyLoading = false;
    },
  },
  mounted() {
    this.GET_COMPANY();
    this.iniFrame();
  },
};
</script>

<style></style>
