// -------------------------------------------- IMPORT FILES START --------------------------------------------------------------
// ## Import Files
import $api from "../Api";

import Endpoints from "../Endpoints/index";
import { UNIQUE_KEY } from "../BaseUrl";

// -------------------------------------------- IMPORT FILES ENDED --------------------------------------------------------------

// ## File GetFile
/**
 *
 * @param { query: { path: string, width: string, height: string}} form
 *
 * @returns
 */
export const GetFile = async (options) => {
  const response = await $api.blob(Endpoints.File.Get(options));    
  return response;
};
// ## File Create
/**
 *
 * @param { file: File } form
 *
 * @returns
 */
export const Create = async (form) => {
  form.append("uniqueKey", UNIQUE_KEY);
  const response = await $api.post(Endpoints.File.Create(), form);
  return response;
};

export default {
  GetFile,
  Create,
};
