import Requests from "@/services/Requests";

export default {
  data() {
    return {
      rooms: [],
      activeRoom: null,
    };
  },
  methods: {
    async getRooms() {
      this.isLoading = true;
      const response = await Requests.Room.GetRoomByCompanyId({
        query: { "filter[companyId]": this.company.id },
      });
      console.log(response, " response room");

      if (response.message === "OK") {
        const rooms = response.data.items;
        if (!rooms.length) return this.noRoomMessage();
        else if (rooms.length === 1) {
          this.isLoading = false;
          this.activeRoom = rooms[0];
          this.selectedRoomMessage(rooms[0]);
        } else if (this.$route.params.roomId) {
          const activeRoom = rooms.find(
            (room) => room.id === this.$route.params.roomId
          );
          if (activeRoom) {
            this.isLoading = false;
            this.activeRoom = activeRoom;
            this.selectedRoomMessage(activeRoom);
          } else this.createMultipleRoomSelectMessage(rooms);
        } else this.createMultipleRoomSelectMessage(rooms);

        this.rooms = rooms;
      }
    },
    noRoomMessage() {
      this.sendBotMessage(
        this.$t("chat.message.bot.noRoom", { name: this.company.name }),
        "",
        () => {}
      );
      this.endChat();
    },
    createMultipleRoomSelectMessage(rooms) {
      const createHtml = rooms
        .map(
          (room) =>
            `<button class="btn-block u-margin-top-small btn btn-light-primary" id="${room.id}">${room.title} </button>`
        )
        .join("");
      this.sendBotMessage(
        this.$t("chat.message.bot.multipleRoom", {
          html: createHtml,
          name: this.company.name,
          count: rooms.length,
        }),
        "",
        () => {}
      );
      this.showTextArea = false;
      const vm = this;
      document.body.addEventListener("click", (e) => {
        if (this.activeRoom) return;

        if (vm.rooms.find((room) => room.id === e.target.id)) {
          vm.activeRoom = rooms.find((room) => room.id === e.target.id);
          vm.selectedRoomMessage(vm.activeRoom);
          vm.isLoading = false;
        }
      });
    },
    selectedRoomMessage(activeRoom) {
      this.sendBotMessage(
        this.$t("chat.message.bot.selectedRoom", {
          title: activeRoom.title,
          name: this.company.name,
        }),
        this.$t("chat.message.bot.searchServicePlaceholder")
      );
      this.getServices();
    },
  },
};
