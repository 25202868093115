var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row u-justify-content-center u-align-items-center"},[_c('div',{staticClass:"u-padding-md-up es-xs-12 es-md-12 es-lg-12"},[_c('div',{staticClass:"s-chat__dates"},_vm._l((_vm.dates),function(d,i){return _c('div',{key:i,on:{"click":function($event){return _vm.GET_RESERVATION(d, i)}}},[(d.dayMean === 'OTHER')?_c('div',{staticClass:"s-chat__dates__items",class:{
              's-chat__dates__items__filled':
                _vm.selectedDate?.isoStringDate === d.isoStringDate,
            }},[_c('p',{staticClass:"u-margin-top-0 u-margin-bottom-xsmall u-font-size-small"},[_vm._v(" "+_vm._s(d.shortWeekDay)+" ")]),_c('h3',[_vm._v(" "+_vm._s(d.day)+" "),_c('br'),_c('small',{staticClass:"u-font-size-xsmall"},[_vm._v(" "+_vm._s(d.shortMonthDay)+" ")])])]):(d.dayMean === 'TODAY')?_c('div',{staticClass:"s-chat__dates__items u-display-flex u-align-items-center u-justify-content-center u-flex-direction-column",class:{
              's-chat__dates__items__filled':
                _vm.selectedDate?.isoStringDate === d.isoStringDate,
            }},[_vm._m(0,true),_c('strong',[_vm._v(" "+_vm._s(_vm.$t("common.today")))])]):(d.dayMean === 'TOMORROW')?_c('div',{staticClass:"s-chat__dates__items u-display-flex u-align-items-center u-justify-content-center u-flex-direction-column",class:{
              's-chat__dates__items__filled':
                _vm.selectedDate?.isoStringDate === d.isoStringDate,
            }},[_vm._m(1,true),_c('strong',[_vm._v(" "+_vm._s(_vm.$t("common.tomorrow")))])]):_vm._e()])}),0),_c('div',[(_vm.selectedDate)?_c('div',[_c('hr'),_c('div',{staticClass:"u-display-flex u-margin-bottom u-width-100% u-justify-content-space-between u-align-items-center"},[(_vm.dateIndex > 0)?_c('i',{staticClass:"u-cursor-pointer icofont-rounded-left",on:{"click":function($event){return _vm.PREV_DATE()}}}):_c('i',{staticClass:"u-cursor-pointer u-color-muted icofont-rounded-left"}),_c('h4',{staticClass:"u-color-primary u-margin-bottom-0"},[_c('i',{staticClass:"u-cursor-pointer icofont-calendar"}),_vm._v(" "+_vm._s(_vm.selectedDate.dateString)+" "+_vm._s(_vm.selectedDate.shortWeekDay)+" ")]),(_vm.dateIndex < _vm.dates.length - 1)?_c('i',{staticClass:"icofont-rounded-right",on:{"click":function($event){return _vm.NEXT_DATE()}}}):_c('i',{staticClass:"u-cursor-pointer u-color-muted icofont-rounded-right"})]),(_vm.isLoading)?_c('loading'):(_vm.reservations.length === 0)?_c('div',[_c('CompanyNotWorking')],1):_c('div',{staticClass:"s-chat__times"},_vm._l((_vm.reservations),function(reservation,i){return _c('div',{key:i,staticClass:"row u-padding-left-xsmall u-padding-right-xsmall u-align-items-center u-margin-bottom"},[_c('div',{staticClass:"es-xs-12"},[_c('strong',[_c('i',{class:`icofont-${reservation.icon} u-color-${reservation.variant}`}),_vm._v(" "+_vm._s(_vm.$t(reservation.label)))])]),_vm._l((reservation.dates),function(d,j){return _c('div',{key:i + '-' + j,staticClass:"es-xs-6 es-md-3 es-lg-2 s-chat__times__items u-margin-xsmall",class:{
                  's-chat__times__items__fulled':
                    d.reservationInfo.length > 0 || d.status !== 'Empty',
                  's-chat__times__items__filled':
                    _vm.selectedTime?.calendarTime === d.calendarTime,
                },on:{"click":function($event){return _vm.SELECT_TIME(d)}}},[_vm._v(" "+_vm._s(d.calendarTime)+" ")])})],2)}),0)],1):_c('div',[_c('FirstChooseDate')],1)]),(_vm.selectedTime)?_c('div',{attrs:{"id":"reservation-footer"}},[_c('p',{staticClass:"o-alert o-alert--info"},[_c('i',{staticClass:"icofont-info-circle"}),_vm._v(" "+_vm._s(_vm.$t("chat.reservation.totalPriceInfo", { price: _vm.totalPrice }))+" ")]),_c('div',{staticClass:"u-display-flex row-gap-10"},[_c('Button',{staticClass:"u-border",attrs:{"variant":"light-primary","block":""},on:{"click":function($event){return _vm.$emit('cancel:reservation')}}},[_vm._v(" "+_vm._s(_vm.$t("common.cancel"))+" ")]),_c('Button',{attrs:{"variant":"primary","block":""},on:{"click":(e, l) =>
                _vm.$emit(
                  'create:reservation',
                  {
                    date: _vm.selectedDate,
                    time: _vm.selectedTime,
                  },
                  l
                )}},[_vm._v(" "+_vm._s(_vm.$t("common.createReservation"))+" ("+_vm._s(_vm.totalPrice)+") ")])],1)]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"u-font-size-xlarge u-color-warning icofont-sun"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"u-font-size-xlarge u-color-warning icofont-sun"})])
}]

export { render, staticRenderFns }