<template>
  <div class="container">
    <div class="row u-justify-content-center u-align-items-center">
      <div class="u-padding-md-up es-xs-12 es-md-12 es-lg-12">
        <div class="s-chat__dates">
          <div @click="GET_RESERVATION(d, i)" v-for="(d, i) in dates" :key="i">
            <div
              v-if="d.dayMean === 'OTHER'"
              class="s-chat__dates__items"
              :class="{
                's-chat__dates__items__filled':
                  selectedDate?.isoStringDate === d.isoStringDate,
              }"
            >
              <p
                class="u-margin-top-0 u-margin-bottom-xsmall u-font-size-small"
              >
                {{ d.shortWeekDay }}
              </p>
              <h3>
                {{ d.day }} <br />
                <small class="u-font-size-xsmall">
                  {{ d.shortMonthDay }}
                </small>
              </h3>
            </div>
            <div
              v-else-if="d.dayMean === 'TODAY'"
              :class="{
                's-chat__dates__items__filled':
                  selectedDate?.isoStringDate === d.isoStringDate,
              }"
              class="s-chat__dates__items u-display-flex u-align-items-center u-justify-content-center u-flex-direction-column"
            >
              <div>
                <i class="u-font-size-xlarge u-color-warning icofont-sun"></i>
              </div>
              <strong> {{ $t("common.today") }}</strong>
            </div>
            <div
              v-else-if="d.dayMean === 'TOMORROW'"
              :class="{
                's-chat__dates__items__filled':
                  selectedDate?.isoStringDate === d.isoStringDate,
              }"
              class="s-chat__dates__items u-display-flex u-align-items-center u-justify-content-center u-flex-direction-column"
            >
              <div>
                <i class="u-font-size-xlarge u-color-warning icofont-sun"></i>
              </div>
              <strong> {{ $t("common.tomorrow") }}</strong>
            </div>
          </div>
        </div>
        <div>
          <div v-if="selectedDate">
            <hr />
            <div
              class="u-display-flex u-margin-bottom u-width-100% u-justify-content-space-between u-align-items-center"
            >
              <i
                @click="PREV_DATE()"
                v-if="dateIndex > 0"
                class="u-cursor-pointer icofont-rounded-left"
              ></i>
              <i
                v-else
                class="u-cursor-pointer u-color-muted icofont-rounded-left"
              ></i>

              <h4 class="u-color-primary u-margin-bottom-0">
                <i class="u-cursor-pointer icofont-calendar"></i>
                {{ selectedDate.dateString }}
                {{ selectedDate.shortWeekDay }}
              </h4>
              <i
                v-if="dateIndex < dates.length - 1"
                @click="NEXT_DATE()"
                class="icofont-rounded-right"
              ></i>
              <i
                v-else
                class="u-cursor-pointer u-color-muted icofont-rounded-right"
              ></i>
            </div>
            <loading v-if="isLoading"></loading>
            <div v-else-if="reservations.length === 0">
              <CompanyNotWorking></CompanyNotWorking>
            </div>
            <div v-else class="s-chat__times">
              <div
                class="row u-padding-left-xsmall u-padding-right-xsmall u-align-items-center u-margin-bottom"
                v-for="(reservation, i) in reservations"
                :key="i"
              >
                <div class="es-xs-12">
                  <strong>
                    <i
                      :class="`icofont-${reservation.icon} u-color-${reservation.variant}`"
                    ></i>
                    {{ $t(reservation.label) }}</strong
                  >
                </div>
                <div
                  v-for="(d, j) in reservation.dates"
                  :key="i + '-' + j"
                  class="es-xs-6 es-md-3 es-lg-2 s-chat__times__items u-margin-xsmall"
                  @click="SELECT_TIME(d)"
                  :class="{
                    's-chat__times__items__fulled':
                      d.reservationInfo.length > 0 || d.status !== 'Empty',
                    's-chat__times__items__filled':
                      selectedTime?.calendarTime === d.calendarTime,
                  }"
                >
                  {{ d.calendarTime }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <FirstChooseDate></FirstChooseDate>
          </div>
        </div>
        <div v-if="selectedTime" id="reservation-footer">
          <p class="o-alert o-alert--info">
            <i class="icofont-info-circle"></i>
            {{ $t("chat.reservation.totalPriceInfo", { price: totalPrice }) }}
          </p>
          <div class="u-display-flex row-gap-10">
            <Button
              variant="light-primary"
              class="u-border"
              block
              @click="$emit('cancel:reservation')"
            >
              {{ $t("common.cancel") }}
            </Button>
            <Button
              variant="primary"
              block
              @click="
                (e, l) =>
                  $emit(
                    'create:reservation',
                    {
                      date: selectedDate,
                      time: selectedTime,
                    },
                    l
                  )
              "
            >
              {{ $t("common.createReservation") }} ({{ totalPrice }})
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../Base/Button.vue";
import Requests from "@/services/Requests";
import CompanyNotWorking from "./CompanyNotWorking.vue";
import FirstChooseDate from "./FirstChooseDate.vue";
export default {
  components: {
    Button,
    CompanyNotWorking,
    FirstChooseDate,
  },
  data() {
    return {
      dateIndex: 0,
      isLoading: true,
      reservations: [],
      dates: [],
      selectedDate: null,
      selectedTime: null,
    };
  },
  props: {
    activeRoom: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
    totalPrice: {
      default: "",
      type: String,
      required: true,
    },
  },
  methods: {
    generateDateArray() {
      const today = new Date();
      const dateArray = [];

      for (let i = 0; i < 20; i++) {
        const date = new Date();
        date.setDate(today.getDate() + i);

        const shortWeekDay = date.toLocaleDateString(this.$i18n.locale, {
          weekday: "short",
        }); // ör: "Mon", "Tue"
        const shortMonthDay = date.toLocaleDateString(this.$i18n.locale, {
          month: "short",
        }); // ör: "Sep", "Oct"
        const dateString = date.toLocaleDateString(this.$i18n.locale, {
          day: "numeric",
          month: "long",
          year: "numeric",
        }); // ör: "Mon", "Tue"
        const day = date.getDate();
        const isoStringDate =
          date.toISOString().split("T")[0] + "T00:00:00.000Z";

        let dayMean;
        if (i === 0) {
          dayMean = "TODAY";
        } else if (i === 1) {
          dayMean = "TOMORROW";
        } else {
          dayMean = "OTHER";
        }

        dateArray.push({
          shortWeekDay,
          day,
          isoStringDate,
          dayMean,
          shortMonthDay,
          dateString,
        });
      }

      return dateArray;
    },
    NEXT_DATE() {
      if (this.dateIndex < this.dates.length - 1) {
        this.dateIndex += 1;
        this.GET_RESERVATION(this.dates[this.dateIndex], this.dateIndex);
      }
    },
    PREV_DATE() {
      if (this.dateIndex > 0) {
        this.dateIndex -= 1;
        this.GET_RESERVATION(this.dates[this.dateIndex], this.dateIndex);
      }
    },
    async GET_RESERVATION(d, index) {
      const date = d.isoStringDate;
      this.dateIndex = index;
      this.selectedDate = d;
      this.selectedTime = null;
      this.isLoading = true;
      const response = await Requests.Reservation.GetActiveTimesByDay({
        query: {
          "filter[roomId]": this.activeRoom.id,
          "filter[companyId]": this.company.id,
          "filter[reservationDate]": date,
        },
      });
      if (response.message === "OK") {
        this.reservations = this.splitReservationsByTime(response.data);
      }
      this.isLoading = false;
    },
    splitReservationsByTime(reservations) {
      const morningAppointments = [];
      const afternoonAppointments = [];
      const eveningAppointments = [];
      const nightAppointments = [];

      reservations.forEach((reservation) => {
        const time = reservation.calendarTime;

        if (time >= "06:00" && time <= "12:59") {
          morningAppointments.push(reservation);
        } else if (time >= "13:00" && time <= "14:59") {
          afternoonAppointments.push(reservation);
        } else if (time >= "15:00" && time <= "19:59") {
          eveningAppointments.push(reservation);
        } else if ((time >= "20:00" && time <= "23:59") || time >= "00:00") {
          nightAppointments.push(reservation);
        }
      });

      const result = [
        {
          label: "common.morning",
          icon: "sun-rise",
          variant: "warning",
          dates: morningAppointments,
        },
        {
          label: "common.afternoon",
          icon: "sun",
          variant: "warning",
          dates: afternoonAppointments,
        },
        {
          label: "common.evening",
          icon: "night",
          variant: "info",
          dates: eveningAppointments,
        },
        { label: "common.night", icon: "full-night", dates: nightAppointments },
      ];

      return result.filter((d) => d.dates.length > 0);
    },
    SELECT_TIME(d) {
      if (d.status !== "Empty") return;
      this.selectedTime = d;
      setTimeout(() => {
        document.getElementById("reservation-footer").scrollIntoView({
          behavior: "smooth",
        });
      }, 500);
    },
  },
  created() {
    this.dates = this.generateDateArray();
    this.GET_RESERVATION(this.dates[0], 0);
  },
};
</script>

<style></style>
