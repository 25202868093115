<template>
  <loading v-if="isLoading"></loading>
  <reservationNotFound v-else-if="reservationNotFound"></reservationNotFound>
  <div v-else class="u-margin-top">
    <div class="u-text-align-center">
      <img src="@/assets/images/confirmed.gif" width="100" alt="" />
      <h3>{{ $t("reservation_is_created") }}</h3>
      <chip variant="primary">
        {{ $t(`reservationDetail.${reservationDetail.reservationStatus}`) }}
      </chip>
    </div>
    <div class="u-margin-top u-border u-padding-xsmall">
      <loading v-if="isLoading"></loading>
      <div v-else>
        <div class="u-text-align-right">
          <chip variant="primary">
            {{ $t(`reservationDetail.${reservationDetail.reservationStatus}`) }}
          </chip>
        </div>
        <div class="u-display-flex u-align-items-center">
          <avatar
            status="online"
            file-sync
            :src="reservationDetail.companyInfo.image"
          ></avatar>
          <div class="u-margin-left-xsmall u-line-1">
            <strong> {{ reservationDetail.companyInfo.name }}</strong>
          </div>
        </div>
        <div class="u-margin-top u-background-color-filtered u-padding-xsmall">
          <div>
            <small>
              {{ $t("reservationDetail.date") }}
            </small>
          </div>
          <div>
            <strong> {{ date(reservationDetail.reservationTime) }}</strong>
            <strong>
              - {{ dateOnlyTime(reservationDetail.reservationEndTime) }}</strong
            >
            <small class="u-color-primary u-margin-left-xsmall"
              >({{ $t("common.avgTime") }})</small
            >
          </div>
        </div>
        <hr />
        <div class="u-margin-top-small">
          <h3>{{ $t("reservationDetail.info") }}</h3>
          <div class="row">
            <div class="es-xs-12 es-md-6">
              <div class="u-margin-top-small">
                <strong>{{ $t("reservationDetail.room") }}:</strong>
                {{ reservationDetail.roomInfo.title }}
              </div>
              <div class="u-margin-top-small">
                <chip
                  variant="secondary"
                  v-for="(service, i) in reservationDetail.companyServicesInfo"
                  :key="i"
                >
                  {{ service.service.name }} ({{
                    Currency({ price: service.price })
                  }}) - {{ service.time }} {{ $t("common.min") }}.
                </chip>
              </div>
              <div class="row">
                <a
                  target="_blank"
                  class="u-color-inherit es-xs-12 es-sm-6"
                  :href="CREATE_LINK(reservationDetail.meetingLink)"
                >
                  <Button
                    variant="light-primary"
                    block
                    class="u-margin-top-small"
                    v-if="reservationDetail.meetingLink"
                  >
                    <strong class="u-margin-right-small"
                      >{{ $t("reservationDetail.meetingLink") }}:</strong
                    >
                    <i class="icofont-external"></i>
                    {{ reservationDetail.meetingLink }}
                  </Button>
                </a>
                <a
                  target="_blank"
                  class="u-color-inherit es-xs-12 es-sm-6"
                  :href="CREATE_LINK(reservationDetail.locationLink)"
                >
                  <Button
                    variant="light-primary"
                    block
                    class="u-margin-top-small u-display-block-md-down"
                    v-if="reservationDetail.locationLink"
                  >
                    <strong class="u-margin-right-small"
                      >{{ $t("reservationDetail.locationLink") }}:</strong
                    >
                    <i class="icofont-external"></i>
                    {{ reservationDetail.locationLink }}
                  </Button>
                </a>
              </div>
            </div>
            <div class="es-xs-12 es-sm-6">
              <div class="u-margin-top-small">
                <small>{{ $t("reservationDetail.price") }}:</small>
                <h2>{{ Currency({ price: reservationDetail.price }) }}</h2>
              </div>
              <p class="o-alert o-alert--info">
                <i class="icofont-info-circle"></i>
                {{
                  $t("chat.reservation.totalPriceInfo", {
                    price: Currency({ price: reservationDetail.price }),
                  })
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Requests from "@/services/Requests";
import Chip from "../Base/Chip.vue";
import Avatar from "../Base/Avatar.vue";
import Button from "../Base/Button.vue";
import { Currency } from "gurhan/helpers/Currency";
import ReservationNotFound from "./ReservationNotFound.vue";

export default {
  components: { Chip, Avatar, ReservationNotFound, Button },
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      reservationDetail: null,
      reservationNotFound: false,
    };
  },
  methods: {
    Currency,
    CREATE_LINK(link) {
      if (!link) return;
      if (link.includes("https://") || link.includes("http://")) {
        return link;
      } else {
        return `https://${link}`;
      }
    },

    date(value) {
      const dateString = new Date(value).toLocaleString(this.$i18n.locale, {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC",
      }); // ör: "Mon", "Tue"
      return dateString;
    },
    dateOnlyTime(value) {
      const dateString = new Date(value).toLocaleString(this.$i18n.locale, {
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC",
      }); // ör: "Mon", "Tue"
      return dateString;
    },
    async GET_RESERVATION() {
      this.isLoading = true;
      const response = await Requests.Reservation.GetById({
        params: { id: this.reservation.id },
      });
      if (response.message === "OK") {
        this.reservationDetail = response.data;
      } else {
        this.reservationNotFound = true;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.GET_RESERVATION();
  },
};
</script>

<style></style>
