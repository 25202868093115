<template>
  <div
    @click="$emit('click', $event)"
    class="c-avatar"
    :class="{
      'c-avatar__variant--text': showText,
      'c-avatar__variant--clickable': clickable,
      'c-avatar__variant--card': type === 'card',
      'c-avatar__variant--huge': size === 'huge',
      'c-avatar__variant--small': size === 'sm',
      'c-avatar__variant--large': size === 'lg',
      'c-avatar__variant--xlarge': size === 'xl',
      'c-avatar__variant--online': status === 'online',
      'c-avatar__variant--offline': status === 'offline',
    }"
  >
    <span
      class="u-display-inline-flex u-align-items-center"
      v-if="fileSyncLoading"
    >
      <loading v-if="fileSyncLoading"></loading>
    </span>
    <slot v-else-if="hasSlot"></slot>
    <img
      v-else-if="!showText"
      :src="syncSrc || src"
      @error="(e) => (showText = true)"
      class="c-avatar__img"
      alt="user-profile"
    />
    <span v-else-if="text !== ''" class="c-avatar__text">{{
      textComputed
    }}</span>
    <i v-else :class="`icofont-${icon}`"></i>
  </div>
</template>

<script>
import FileRequests from "@/services/FileService/Requests/index";
export default {
  props: {
    src: {
      default: "",
    },
    text: {
      default: "",
    },
    size: {
      default: "",
    },
    status: {
      default: "",
    },
    icon: {
      default: "user",
    },
    type: {
      default: "circle",
    },
    fileSync: {
      default: false,
      type: Boolean,
    },
    clickable: {
      default: false,
      type: Boolean,
    },
    image: {
      default: "",
    },
  },
  computed: {
    textComputed() {
      if (this.text.length < 2) return this.text;
      return this.text.slice(0, 2);
    },
  },
  watch: {
    src(data) {
      if (this.fileSync && this.src) this.getFileUrl(this.src);
      if (data !== "") this.showText = false;
      else this.showText = true;
    },
  },
  data() {
    return {
      sizes: [
        {
          name: "sm",
          width: 30,
        },
        {
          name: "",
          width: 40,
        },
        {
          name: "lg",
          width: 80,
        },
        {
          name: "xl",
          width: 120,
        },
        {
          name: "huge",
          width: 300,
        },
      ],
      hasSlot: false,
      showText: false,
      fileSyncLoading: false,
      syncSrc: "",
    };
  },
  methods: {
    async getFileUrl(path) {
      if (path.startsWith("blob:") || path.startsWith("data:"))
        return (this.syncSrc = path);
      if (this.syncSrc) return;
      const getSizesOfImage = this.sizes.find((s) => s.name === this.size) || {
        width: 200,
      };
      this.fileSyncLoading = true;
      const url = await FileRequests.File.GetFile({
        query: { path: path, width: getSizesOfImage.width },
      });
      this.syncSrc = url;
      this.fileSyncLoading = false;
    },
  },
  mounted() {
    if (this.fileSync && this.src) this.getFileUrl(this.src);
    if (!this.src) this.showText = true;
    if (this.$scopedSlots.default && this.$scopedSlots.default().length > 0) {
      this.hasSlot = true;
    }
  },
};
</script>

<style></style>
